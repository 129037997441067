import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M21 19V5C20.9984 4.47005 20.7872 3.96227 20.4125 3.58753C20.0377 3.2128 19.5299 3.00158 19 3H5C4.47005 3.00158 3.96227 3.2128 3.58753 3.58753C3.2128 3.96227 3.00158 4.47005 3 5V19C3.00158 19.5299 3.2128 20.0377 3.58753 20.4125C3.96227 20.7872 4.47005 20.9984 5 21H19C19.5299 20.9984 20.0377 20.7872 20.4125 20.4125C20.7872 20.0377 20.9984 19.5299 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'InsertPhotoIcon'

export const InsertPhotoIcon = memo<IconProps>(themed(Icon))
