import { Button, Spinner, Text, XStack, YStack, useMedia, useTheme, useThemeName } from '@bp/ui'
import { connect } from 'getstream'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FlatList } from 'react-native'
import Post from './post'
import { useEffect } from 'react'
import { SolitoImage } from 'solito/image'
import emptyFeed from './emptyFeed.png'
import emptyFeedDark from './emptyFeedDark.png'
import { useLink } from 'solito/link'

export default function FeedInner({ userId, feedSlug, accessToken }) {
  const API_KEY = process.env.BP_PUBLIC_GS_KEY
  const APP_ID = process.env.BP_PUBLIC_GS_ID
  const queryClient = useQueryClient()
  const client = connect(API_KEY, accessToken, APP_ID)
  const feed = client.feed(feedSlug, userId)
  const { data: gsFeedData, isPending: gsFeedIsPending } = useQuery({
    queryKey: ['gsFeedData', feedSlug, userId],
    queryFn: () =>
      feed.get({
        limit: 100,
        enrich: true,
        reactions: { own: true, counts: true, recent: true },
      }),
  })

  useEffect(() => {
    const callback = (data) => {
      queryClient.invalidateQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
    }
    const successCallback = () => {}
    const failCallback = (data) => {
      console.error('subscribe something went wrong, check the console logs', data)
    }
    const subscription = feed.subscribe(callback).then(successCallback, failCallback)
    return () => subscription?.cancel && subscription.cancel()
  }, [])

  const findNewPeopleLink = useLink({
    href: '/discover',
  })

  const media = useMedia()
  const themeName = useThemeName()
  const isDarkTheme = themeName.includes('dark')

  if (gsFeedIsPending) {
    return <Spinner />
  }

  console.log('gsFeedData.results', gsFeedData.results)
  return (
    <YStack>
      {gsFeedData && (
        <FlatList
          data={gsFeedData.results}
          renderItem={(post) => <Post post={post} userId={userId} feedSlug={feedSlug} />}
          keyExtractor={(post) => post.id}
        />
      )}
      {gsFeedData && feedSlug !== 'user' && gsFeedData.results.length === 0 && (
        <YStack justifyContent="center">
          <XStack justifyContent="center" width="100%" height={media.sm ? 200 : 400}>
            <SolitoImage src={isDarkTheme ? emptyFeedDark : emptyFeed} alt="Empty feed" fill />
          </XStack>
          <XStack justifyContent="center" marginTop="$3" marginBottom="$6">
            <Button {...findNewPeopleLink} variant="primary">
              Find new people
            </Button>
          </XStack>
        </YStack>
      )}
    </YStack>
  )
}
