import { timeAgo } from 'app/utils/date'
import { ChatIcon, ThumbUpIcon, MoreVerticalIcon, CloseCrossIcon } from '@bp/ui/src/Iconsx'
import { Popover, Adapt, Paragraph, XStack, YStack, Avatar } from 'tamagui'
import { Button, NiceLink, Separator, Spinner, Text } from '@bp/ui'
import NewComment from './newComment'
import API from 'app/api'
import { useState } from 'react'
import { SolitoImage } from 'solito/image'
import { useUserInfoStore } from 'app/store'

function Post({ post, userId, feedSlug }) {
  const { item } = post
  let itemData = item
  if (item.activities && item.activities.length > 0) {
    itemData = item.activities[0]
  }
  const { actor, own_reactions: ownReactions } = itemData
  let postObject

  try {
    postObject = JSON.parse(itemData.object)
  } catch (e) {
    // For better testing in case of parse error
    console.error('For better testing:', e)
    return (
      <YStack
        backgroundColor="$white"
        elevation={5}
        borderWidth={1}
        borderColor="$borderColor"
        borderRadius={8}
        padding={16}
        marginBottom={20}
      >
        <Text textAlign="center">{itemData.object}</Text>
        {itemData.pending && <Spinner marginTop="$3" />}
      </YStack>
    )
  }

  const viewerId = useUserInfoStore((state) => state.id)
  const isOwn = parseInt(actor.id) === parseInt(viewerId)
  const [liked, setLiked] = useState(ownReactions?.like?.length)
  const [likes, setLikes] = useState(itemData.reaction_counts.like)
  const onPostLike = async () => {
    let response
    if (liked) {
      response = await API.post.unlike(postObject.id)
      setLiked(false)
    } else {
      response = await API.post.like(postObject.id)
      setLiked(true)
    }
    setLikes(response.total_likes)
  }

  const handleFlagPost = async () => {
    try {
      await API.flag.flagPost(postObject.id)
      alert('Post reported successfully')
    } catch (e) {
      console.error('Error while flagging post', e)
    }
  }

  const handleDeletePost = async () => {
    try {
      await API.post.deletePost(postObject.id)
      alert('Post deleted successfully')
    } catch (e) {
      console.error('Error while deleting post', e)
    }
  }

  return (
    <YStack
      backgroundColor="$white"
      elevation={5}
      borderWidth={1}
      borderColor="$borderColor"
      borderRadius={8}
      padding={16}
      marginBottom={20}
      gap="$4"
    >
      <XStack gap="$3">
        <NiceLink href={`/user/${actor.data?.username}`}>
          <Avatar circular size={44}>
            <Avatar.Image
              accessibilityLabel={`${actor.data?.nickname} avatar`}
              src={actor.data?.avatar.md}
            />
            <Avatar.Fallback backgroundColor="$neonGreen" />
          </Avatar>
        </NiceLink>
        <YStack flex={1}>
          <NiceLink href={`/user/${actor.data?.username}`}>
            <Text fontWeight="bold" fontSize={18}>
              {actor.data?.nickname}
            </Text>
          </NiceLink>
          <XStack gap="$2">
            <NiceLink href={`/user/${actor.data?.username}`}>
              <Text>{actor.data?.username}</Text>
            </NiceLink>
            <Text>•</Text>
            <Text>{timeAgo(itemData.time)}</Text>
          </XStack>
        </YStack>
        <XStack>
          <Popover placement="left">
            <Popover.Trigger asChild>
              <Button icon={<MoreVerticalIcon size="$1.2" />} paddingRight="0" />
            </Popover.Trigger>

            <Popover.Content
              padding={0}
              paddingBottom="$2"
              borderWidth={1}
              borderColor="$borderColor"
              enterStyle={{ y: -10, opacity: 0 }}
              exitStyle={{ y: -10, opacity: 0 }}
              elevate
              animation={[
                'quick',
                {
                  opacity: {
                    overshootClamping: true,
                  },
                },
              ]}
            >
              <Popover.Arrow />
              <Popover.Close asChild>
                <Button alignSelf="flex-end">
                  <CloseCrossIcon />
                </Button>
              </Popover.Close>
              <YStack gap="$3" padding="$3" minWidth={120}>
                {isOwn && (
                  <Button onPress={handleDeletePost} variant="danger" size="$3">
                    Delete
                  </Button>
                )}
                <Button onPress={handleFlagPost} variant="secondary" size="$3">
                  Report
                </Button>
              </YStack>
            </Popover.Content>

            <Adapt when="sm" platform="touch">
              <Popover.Sheet modal dismissOnSnapToBottom>
                <Popover.Sheet.Frame padding="$4">
                  <Adapt.Contents />
                </Popover.Sheet.Frame>
                <Popover.Sheet.Overlay
                  animation="lazy"
                  enterStyle={{ opacity: 0 }}
                  exitStyle={{ opacity: 0 }}
                />
              </Popover.Sheet>
            </Adapt>
          </Popover>
        </XStack>
      </XStack>
      <Paragraph>{postObject.body}</Paragraph>
      {postObject.type === 'photo' && postObject.media && (
        <YStack>
          {postObject.media.map((image, i) => (
            <XStack width="100%" minHeight={220} key={i}>
              <SolitoImage resizeMode="contain" src={image?.lg || image?.md || image?.sm} fill />
            </XStack>
          ))}
        </YStack>
      )}
      <YStack gap="$2">
        <Separator />
        <XStack justifyContent="flex-end">
          <Button color="$mediumDark" transparent icon={ChatIcon}>
            <Text color="$mediumDark">{itemData.reaction_counts.comment || 0}</Text>
          </Button>
          <Button
            variant={liked ? 'primary' : ''}
            transparent={liked}
            icon={ThumbUpIcon}
            onPress={onPostLike}
          >
            <Text color={liked ? '$lightest' : '$mediumDark'}>{likes || 0}</Text>
          </Button>
        </XStack>
        <Separator />
        <YStack gap="$4" mt="$3">
          {itemData.latest_reactions?.comment?.reverse().map((comment) => (
            <XStack gap="$4" key={comment.id}>
              <Avatar circular size={44}>
                <Avatar.Image accessibilityLabel="Cam" src={comment.user?.data?.avatar?.md} />
                <Avatar.Fallback backgroundColor="$neonGreen" />
              </Avatar>
              <YStack flex={1} backgroundColor="$lighter" borderRadius="$4" padding="$3" gap="$2">
                <XStack gap="$2">
                  <Text bold>{comment.user?.data?.nickname}</Text>
                  <Text>•</Text>
                  <Text>{timeAgo(comment.created_at)}</Text>
                </XStack>
                <Text>{comment.data.body}</Text>
                {comment.data.type === 'photo' && comment.data.media && (
                  <YStack>
                    {comment.data.media.map((image) => (
                      <XStack width="100%" minHeight={220}>
                        <SolitoImage
                          resizeMode="contain"
                          src={image?.lg || image?.md || image?.sm}
                          fill
                        />
                      </XStack>
                    ))}
                  </YStack>
                )}
              </YStack>
            </XStack>
          ))}
          {itemData.latest_reactions?.comment?.length && <Separator pb="$1" />}
          <NewComment
            pid={postObject.id || itemData.foreign_id}
            feedSlug={feedSlug}
            userId={userId}
          />
        </YStack>
      </YStack>
    </YStack>
  )
}

export default Post
