import { decode } from 'base-64'
global.atob = decode // Fix for https://github.com/auth0/jwt-decode/issues/241
import API from 'app/api'
import { Spinner } from '@bp/ui'
import { useQuery } from '@tanstack/react-query'
import FeedInner from './feedInner'

export default function Feed({ userId, feedSlug }) {
  const { data: gsAccessTokenData, isPending } = useQuery({
    queryFn: API.user.getStreamIoToken,
    queryKey: ['gsAccessToken'],
  })

  if (isPending) {
    return <Spinner />
  }

  return (
    <FeedInner feedSlug={feedSlug} userId={userId} accessToken={gsAccessTokenData?.access_token} />
  )
}
