import { useState } from 'react'
import API from 'app/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { XStack, YStack, Button, Spinner, TextField } from '@bp/ui'
import { InsertPhotoIcon } from '@bp/ui/src/Iconsx'
import * as ImagePicker from 'expo-image-picker'
import { SolitoImage } from 'solito/image'

function NewComment({ userId, pid, feedSlug }) {
  const queryClient = useQueryClient()
  const [commentText, setCommentText] = useState()

  const addCommentMutation = useMutation({
    mutationFn: ({ commentText, type, images }) =>
      API.post.createComment(pid, commentText, type, images),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['gsFeedData', feedSlug, userId] })
      setCommentText('')
      setPicture(undefined)
    },
  })

  const createNewComment = async () => {
    try {
      const type = picture ? 'photo' : 'text'
      const images = type === 'photo' ? [`data:image/png;base64,${picture.base64}`] : undefined
      addCommentMutation.mutate({ commentText, type, images })
    } catch (e) {}
  }

  const [picture, setPicture] = useState(undefined)
  const onUploadImage = async () => {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        return
      }

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true,
      })
      if (!result.canceled) {
        setPicture(result.assets[0])
      }
    } catch (e) {}
  }

  return (
    <YStack gap="$3">
      <XStack gap="$3">
        <YStack flex={1}>
          <TextField
            flex={1}
            value={commentText}
            placeholder="Say something..."
            onChangeText={(value) => setCommentText(value)}
            inputProps={{ paddingRight: 50 }}
          />
          <Button
            onPress={onUploadImage}
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 999 }}
            icon={<InsertPhotoIcon size="$1.2" color="$secondaryDarker" />}
          />
        </YStack>
        <Button
          iconAfter={addCommentMutation.isPending && Spinner}
          variant="primary"
          disabled={addCommentMutation.isPending}
          onPress={createNewComment}
        >
          Send
        </Button>
      </XStack>
      {picture && (
        <XStack justifyContent="center">
          <SolitoImage
            alt="Post image"
            key="imageUpload"
            width={320}
            height={300}
            src={picture.uri}
          />
        </XStack>
      )}
    </YStack>
  )
}

export default NewComment
