import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M2 21H5.63636V10.2H2V21ZM22 11.1C21.9986 10.623 21.8065 10.166 21.4659 9.82878C21.1252 9.49152 20.6636 9.30142 20.1818 9.3H14.4455L15.3091 5.187L15.3364 4.899C15.3355 4.54118 15.1917 4.19821 14.9364 3.945L13.9727 3L7.99091 8.931C7.82 9.09601 7.68442 9.29343 7.59227 9.51145C7.50011 9.72948 7.45328 9.96365 7.45455 10.2V19.2C7.45598 19.6769 7.648 20.134 7.98867 20.4712C8.32933 20.8085 8.79096 20.9986 9.27273 21H17.4545C17.8118 21.0012 18.1614 20.8976 18.4591 20.7021C18.7569 20.5067 18.9894 20.2283 19.1273 19.902L21.8727 13.557C21.9568 13.3479 21.9999 13.125 22 12.9V11.1Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'ThumbUpIcon'

export const ThumbUpIcon = memo<IconProps>(themed(Icon))
